import React from 'react'

import OfferHeroSection from '../components/CustomOfferPage/OfferHeroSection/OfferHeroSection'
import OfferProductSection from '../components/CustomOfferPage/OfferProductionSection/OfferProductSection'
import OfferDesignSection from '../components/CustomOfferPage/OfferDesignSection/OfferDesignSection'
import OfferSoftwareSection from '../components/CustomOfferPage/OfferSoftwareSection/OfferSoftwareSection'

const HowWeWork = () => (
  <>
    <OfferHeroSection />
    <OfferProductSection />
    <OfferDesignSection />
    <OfferSoftwareSection />
  </>
)

export default HowWeWork
