import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  largeBreakpoint,
  mediumBreakpoint,
  xlargeBreakpoint,
} from '../../../config/css-constants'
import softwareBackgroundMobile from '../../../images/offer/softwaremobilebg.svg'
import {
  movingBall,
  movingBallReverse,
  scaling,
} from '../../../assets/keyframes/keyframes'

export const OfferSection = styled.section`
  margin-top: -40px;
  padding: 250px 60px 100px 60px;
  position: relative;
  background-image: url(${softwareBackgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 350px 70px 100px 70px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    padding: 150px 60px 150px 60px;
    margin-top: 0;
    background-image: unset;
    background-color: #f9c249;
    color: #000;
  }
  @media all and (min-width: ${xlargeBreakpoint}) {
    padding-left: 7rem;
    padding-right: 3rem;
  }
`

export const OfferMobilePaper = styled.img`
  position: absolute;
  width: 150px;
  top: -40px;
  right: 40px;
  @media all and (min-width: ${largeBreakpoint}) {
    display: none;
  }
`

export const OfferLink = styled((props) => <Link {...props} />)`
  background-color: #357e8d;
  border: 2px solid transparent;
  color: #fff;
  transition: all 0.4s ease;
  padding: 8px 25px;
  border-radius: 12px;
  display: inline-block;
  text-align: center;

  &:hover {
    color: #357e8d;
    border: 2px solid #357e8d;
    background-color: unset;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    background-color: #357778;
    color: #fff;
    padding: 8px 25px;
    line-height: 1.6rem;
    font-weight: 400;
    font-size: 1.4rem;
    margin-left: 5%;
  }
`

export const ElephantImage = styled.img`
  display: none;
  position: absolute;
  bottom: 10%;
  right: 35%;
  width: 100px;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
  }
`

export const WingsImage = styled.img`
  display: none;
  position: absolute;
  right: 10%;
  top: 15%;
  width: 100px;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
  }
`

export const SignImage = styled.img`
  display: none;
  position: absolute;
  width: 100px;
  left: 40%;
  bottom: 20%;
  animation-name: ${movingBall};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
  }
`

export const UnicornImage = styled.img`
  display: none;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  right: 20%;
  width: 130px;
  animation-name: ${movingBallReverse};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
  }
`

export const ReactImage = styled.img`
  display: none;
  position: absolute;
  bottom: 10%;
  right: 15%;
  width: 130px;
  background-color: #fff;
  border-radius: 50%;
  animation-name: ${movingBall};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
  }
`
