import { largeBreakpoint, fullhd } from '../../../config/css-constants'
import styled from 'styled-components'
import {
  fadeFromLeft,
  rotate360,
  rotate360reverse,
} from '../../../assets/keyframes/keyframes'

export const DesignSection = styled.section`
  padding: 100px 80px 40px 80px;
  position: relative;
  @media all and (min-width: ${largeBreakpoint}) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
`

export const DesignHandImg = styled.img`
  position: absolute;
  width: 80px;
  top: 40px;
  right: 50px;
  transform: scale(0.3);
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-name: ${(props) => (props.isvisible ? rotate360 : '')};
  @media all and (min-width: ${fullhd}) {
    top: unset;
    right: 10%;
    bottom: 15%;
    width: 120px;
  }
`

export const DesignComputerImg = styled.img`
  position: absolute;
  width: 70px;
  right: 15px;
  top: 160px;
  transform: scale(0.3);
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-name: ${(props) => (props.isvisible ? rotate360reverse : '')};
  @media all and (min-width: ${fullhd}) {
    top: unset;
    bottom: 25%;
    width: 120px;
    right: 30%;
  }
`

export const DesignPencilImg = styled.img`
  position: absolute;
  width: 80px;
  left: 30px;
  bottom: -10px;
  z-index: 2;
  transform: scale(0.3);
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-name: ${(props) => (props.isvisible ? rotate360 : '')};
  @media all and (min-width: ${largeBreakpoint}) {
    left: 15%;
    bottom: 5%;
  }
  @media all and (min-width: ${fullhd}) {
    top: unset;
    bottom: 15%;
    width: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const DesignCircleWrapper = styled.div`
  min-height: 300px;
  text-align: center;
`

export const DesignCircleImage = styled.img`
  max-width: 300px;
  margin: 0 auto 20px;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
    max-width: unset;
    width: 100%;
  }
  @media all and (min-width: ${fullhd}) {
    max-width: 550px;
    opacity: 0;
    transform: translateX(-100%);
    animation-fill-mode: forwards;
    animation-name: ${(props) => (props.isvisible ? fadeFromLeft : '')};
    animation-duration: 1.5s;
    animation-timing-function: ease-in;
  }
`

export const DesignWrapper = styled.div`
  @media all and (min-width: ${largeBreakpoint}) {
    display: grid;
    grid-template-columns: 40% 60%;
  }
`

export const DesignTextWrapper = styled.div`
  @media all and (min-width: ${largeBreakpoint}) {
    max-width: 90%;
  }
`
