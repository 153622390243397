import React from 'react'
import VisibilitySensor from '../../VisibilitySensor/VisibilitySensor'

import {
  DesignSection,
  DesignHandImg,
  DesignComputerImg,
  DesignPencilImg,
  DesignCircleImage,
  DesignWrapper,
  DesignTextWrapper,
  DesignCircleWrapper,
} from './styles'

import { SectionTitle, SectionDescription } from '../reusableOfferStyles'

import handImage from '../../../images/offer/13ikonki-21.svg'
import computerImage from '../../../images/offer/12ikonki-20.svg'
import pencilImage from '../../../images/offer/11ikonki-19.svg'
import circleMapImage from '../../../images/offer/10kula_z_mapa-23.svg'

const OfferDesignSection = () => (
  <DesignSection>
    <DesignWrapper>
      <VisibilitySensor once>
        {({ isVisible }) => (
          <DesignCircleWrapper>
            <DesignCircleImage
              isvisible={isVisible ? 1 : 0}
              src={circleMapImage}
              title={'design image'}
            />
          </DesignCircleWrapper>
        )}
      </VisibilitySensor>
      <DesignTextWrapper>
        <SectionTitle>Design ux/ui</SectionTitle>
        <SectionDescription>
          We ensure that your product makes sense to the user. We create a path
          that logically flows from one step to the next, making your product
          more appealing to the client.
        </SectionDescription>
      </DesignTextWrapper>
    </DesignWrapper>

    <VisibilitySensor once>
      {({ isVisible }) => (
        <>
          <DesignHandImg
            isvisible={isVisible ? 1 : 0}
            src={handImage}
            alt={''}
            title={'hand icon'}
          />
          <DesignComputerImg
            isvisible={isVisible ? 1 : 0}
            src={computerImage}
            alt={''}
            title={'computer icon'}
          />
          <DesignPencilImg
            isvisible={isVisible ? 1 : 0}
            src={pencilImage}
            alt={''}
            title={'pencil icon'}
          />
        </>
      )}
    </VisibilitySensor>
  </DesignSection>
)

export default OfferDesignSection
