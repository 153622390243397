import React from 'react'

import {
  PageContainer,
  HeroInformation,
  TopHeroCloud,
  ScrollBottomWrapper,
  ScrollToLink,
  HeroSection,
  Clouds,
  CloudsWrapper,
} from './styles'

const OfferHeroSection = () => (
  <PageContainer>
    <HeroSection>
      <HeroInformation>
        We use the latest technology to help firms realise their potential and
        enable them to grow. By working closely with our clients throughout the
        whole production process, we are able to successfully produce
        first-class bespoke software even within two weeks of development.
      </HeroInformation>
      <TopHeroCloud />
      <CloudsWrapper>
        <Clouds />
      </CloudsWrapper>
      <ScrollBottomWrapper>
        <ScrollToLink />
      </ScrollBottomWrapper>
    </HeroSection>
  </PageContainer>
)

export default OfferHeroSection
