import React from 'react'
import { Link } from 'gatsby'
import {
  mediumBreakpoint,
  largeBreakpoint,
  xlargeBreakpoint,
  xsmallBreakpoint,
  fullhd,
} from '../../../config/css-constants'
import styled from 'styled-components'
import HeroBg from '../../../images/offer/02samolotilustracja-01.svg'
import HeroBgMobile from '../../../images/offer/mobile-bg.svg'
import cloudImage from '../../../images/offer/01bIkonki-14.svg'
import scrollBottomArrow from '../../../images/offer/04arrow.svg'
import {
  cloudsAnimationLeft,
  cloudsAnimationRight,
  moveLeftRight,
} from '../../../assets/keyframes/keyframes'
import CloudsImage from '../../../assets/clouds.svg'

export const CloudsWrapper = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 0;
  right: 15%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (min-width: ${largeBreakpoint}) {
    right: 50px;
    width: 600px;
    bottom: 20px;
  }
`

export const Clouds = styled(CloudsImage)`
  width: 150px;
  position: relative;
  overflow: inherit;
  path:nth-of-type(1) {
    animation-name: ${cloudsAnimationLeft};
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  path:nth-of-type(2) {
    animation-name: ${cloudsAnimationRight};
    animation-duration: 15s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    width: 250px;
  }
`

export const PageContainer = styled.div`
  @media all and (min-width: ${largeBreakpoint}) {
    padding-left: 1rem;
    padding-right: 3rem;
  }
  @media all and (min-width: ${xlargeBreakpoint}) {
    padding-left: 3rem;
    padding-right: 7rem;
  }
`

export const HeroSection = styled.section`
  position: relative;
  overflow: hidden;
  background-image: url(${HeroBgMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: -20px;
  padding-bottom: 60px;
  @media all and (min-width: ${xsmallBreakpoint}) {
    background-size: contain;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 150px 0;
    background-image: url(${HeroBg});
    background-size: cover;
    background-position-x: -50px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    margin-top: 50px;
    padding-top: 70px;
    background-size: 1600px;
    background-position-x: 50px;
    display: flex;
    flex-direction: column;
    min-height: 800px;
  }
`

export const HeroInformation = styled.p`
  padding: 90px 60px 130px 80px;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1rem;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 30px 150px 130px 150px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    max-width: 55%;
    padding-left: 0px;
    padding-top: 0;
    padding-right: 20%;
    align-self: flex-end;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  @media all and (min-width: ${xlargeBreakpoint}) {
    padding-top: 50px;
  }
  @media all and (min-width: ${fullhd}) {
    max-width: 60%;
    &:after {
      content: '';
      opacity: 0;
      transform: translateX(-50%);
      animation-fill-mode: forwards;
      animation-name: ${moveLeftRight};
      animation-duration: 7s;
      animation-timing-function: ease-in;
      animation-iteration-count: infinite;
      background-image: url(${cloudImage});
      background-repeat: no-repeat;
      background-size: 120px;
      display: block;
      width: 120px;
      height: 120px;
      margin-top: 30px;
      margin-left: 15%;
    }
  }
`

export const TopHeroCloud = styled.div`
  background-image: url(${cloudImage});
  background-repeat: no-repeat;
  position: absolute;
  width: 150px;
  height: 100px;
  top: 110px;
  right: -90px;
  animation-name: ${cloudsAnimationRight};
  animation-duration: 7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  @media all and (min-width: ${largeBreakpoint}) {
    right: 50px;
    width: 100px;
    top: 0;
  }
`

export const ScrollBottomWrapper = styled.div`
  display: none;
  @media all and (min-width: ${largeBreakpoint}) {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    transition: all 0.5s ease;
    z-index: 2;
    &:hover {
      opacity: 0.5;
    }
  }
`

export const ScrollBottomArrow = styled.img`
  width: 40px;
  cursor: pointer;
`

export const ScrollToLink = () => (
  <Link to="/offer#product-strategy" title={'scroll arrow'}>
    <ScrollBottomArrow src={scrollBottomArrow} />
  </Link>
)
