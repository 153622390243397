import styled from 'styled-components'
import { largeBreakpoint } from '../../config/css-constants'

export const SectionTitle = styled.h2`
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 15px;
  @media all and (min-width: ${largeBreakpoint}) {
    letter-spacing: 2px;
    font-size: 1.8rem;
  }
`

export const SectionDescription = styled.p`
  padding-bottom: 50px;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1rem;
  @media all and (min-width: ${largeBreakpoint}) {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    margin-bottom: 100px;
  }
`

export const StrategyAndSoftwareText = styled.div`
  @media all and (min-width: ${largeBreakpoint}) {
    width: 50%;
    margin-left: 5%;
  }
`
