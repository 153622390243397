import React from 'react'

import mobilePaper from '../../../images/offer/14mobile-40.svg'
import elephantImage from '../../../images/offer/elephant.svg'
import signImage from '../../../images/offer/sign.svg'
import unicornImage from '../../../images/offer/unicorn.svg'
import wingsImage from '../../../images/offer/wings.svg'
import reactImage from '../../../images/offer/react.svg'

import {
  ElephantImage,
  OfferMobilePaper,
  OfferSection,
  SignImage,
  UnicornImage,
  ReactImage,
  WingsImage,
} from './styles'

import {
  SectionTitle,
  SectionDescription,
  StrategyAndSoftwareText,
} from '../reusableOfferStyles'

const OfferSoftwareSection = () => (
  <OfferSection>
    <StrategyAndSoftwareText>
      <SectionTitle>Software development</SectionTitle>
      <SectionDescription>
        We design intelligent, cost-effective and intuitive software. In
        addition, we also take over our clients’ already existing software. Our
        software development services include: researching, designing, managing,
        testing and evaluating.
      </SectionDescription>
    </StrategyAndSoftwareText>
    <OfferMobilePaper src={mobilePaper} alt={''} />
    {/* uncomment this when there will be technologies page fully translated and active*/}
    {/*<OfferLink to="/technologies">Technologies</OfferLink>*/}
    <ElephantImage src={elephantImage} alt={''} title={'elephant icon'} />
    <WingsImage src={wingsImage} alt={''} title={'wings icon'} />
    <SignImage src={signImage} alt={''} title={'sign icon'} />
    <UnicornImage src={unicornImage} alt={''} title={'unicorn icon'} />
    <ReactImage src={reactImage} alt={''} title={'react logotype'} />
  </OfferSection>
)

export default OfferSoftwareSection
