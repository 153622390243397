import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  xsmallBreakpoint,
  largeBreakpoint,
  xlargeBreakpoint,
} from '../../../config/css-constants'

import boardImage from '../../../images/offer/blackboard.svg'
import wavesBg from '../../../images/howWeWork/falaHowWeWork.svg'
import { rotate360, scaling } from '../../../assets/keyframes/keyframes'

export const ProductWrapper = styled.div`
  @media all and (min-width: ${largeBreakpoint}) {
    max-width: 90%;
    margin: 0 auto;
  }
`

export const ProductSection = styled.section`
  background-color: #f9c249;
  padding: 80px 80px 50px 80px;
  position: relative;
  @media all and (min-width: ${xsmallBreakpoint}) {
    padding: 100px 80px 100px 80px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    background-image: url(${wavesBg});
    background-position: bottom;
    background-repeat: no-repeat;
    padding-bottom: 400px;
  }
  @media all and (min-width: ${xlargeBreakpoint}) {
    padding-top: 150px;
    padding-bottom: 450px;
  }
`

export const ProductLink = styled((props) => <Link {...props} />)`
  background-color: #357e8d;
  border: 2px solid transparent;
  color: #fff;
  transition: all 0.4s ease;
  padding: 6px 10px;
  border-radius: 12px;
  &:hover {
    color: #fff;
    border: 2px solid #357e8d;
    background-color: unset;
  }
  @media all and (min-width: ${xsmallBreakpoint}) {
    padding: 8px 25px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    position: absolute;
    bottom: 100px;
    min-width: 200px;
    text-align: center;
    padding: 8px 25px;
    border-radius: 20px;
    font-size: 1.4rem;
    font-weight: 500;
  }
`

export const ProductHandImg = styled.img`
  width: 65px;
  height: 65px;
  position: absolute;
  top: 50px;
  right: 20px;
  transform: scale(0.3);
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-name: ${(props) => (props.isvisible ? rotate360 : '')};
  @media all and (min-width: ${largeBreakpoint}) {
    width: 150px;
    height: 150px;
    top: 150px;
    right: 10%;
  }
`

export const ProductBoardImg = styled.div`
  background-image: url(${boardImage});
  background-size: cover;
  width: 65px;
  height: 65px;
  position: absolute;
  left: 5px;
  bottom: 180px;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    width: 110px;
    height: 110px;
    bottom: 45%;
    right: 5%;
    left: unset;
  }
`

export const ProductFirstWhiteDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 190px;
  right: 50px;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    width: 30px;
    height: 30px;
    bottom: 150px;
    left: unset;
    right: 10%;
  }
`

export const ProductSecondWhiteDot = styled.div`
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    bottom: 30%;
    left: 25%;
    width: 20px;
    height: 20px;
  }
`

export const ProductBlackDot = styled.div`
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    position: absolute;
    background-color: #000;
    border-radius: 50%;
    top: 30%;
    right: 30%;
    width: 30px;
    height: 30px;
  }
`

export const ProductBulbImage = styled.img`
  display: none;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
    width: 120px;
    position: absolute;
    right: 25%;
    bottom: 100px;
  }
`

export const ProductMagnifierImage = styled.img`
  display: none;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
    width: 120px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 250px;
  }
`
