import React from 'react'
import {
  ProductBlackDot,
  ProductBoardImg,
  ProductBulbImage,
  ProductFirstWhiteDot,
  ProductHandImg,
  ProductLink,
  ProductMagnifierImage,
  ProductSecondWhiteDot,
  ProductSection,
  ProductWrapper,
} from './styles'

import {
  SectionTitle,
  SectionDescription,
  StrategyAndSoftwareText,
} from '../reusableOfferStyles'

import bulbImage from '../../../images/offer/bulb.svg'
import magnifierImage from '../../../images/offer/magnifier.svg'
import VisibilitySensor from '../../VisibilitySensor/VisibilitySensor'
import handImage from '../../../images/offer/hand.svg'

const OfferProductSection = () => (
  <ProductSection id="product-strategy">
    <ProductWrapper>
      <StrategyAndSoftwareText>
        <SectionTitle>Product strategy</SectionTitle>
        <SectionDescription>
          We base our product strategy services on vision, goals, and
          initiative. By working closely with our clients, we are able to come
          up with a tailored strategy for a product or service. Our individual
          approach backed by technology helps our clients to realise their full
          potential and achieve targeted goals. As we are aware of dynamically
          changing market trends, we go beyond the expected work by continuously
          monitoring markets and trends. As such, we are able to offer our
          customers well-research advice and adjust their strategies to ongoing
          changes.
        </SectionDescription>
      </StrategyAndSoftwareText>
      <ProductLink to="/how-we-work">How We work?</ProductLink>
      <VisibilitySensor once>
        {({ isVisible }) => (
          <ProductHandImg
            isvisible={isVisible ? 1 : 0}
            alt={''}
            src={handImage}
            title={'hands icon'}
          />
        )}
      </VisibilitySensor>
      <ProductBoardImg alt={''} title={'blackboard icon'} />
      <ProductFirstWhiteDot />
      <ProductSecondWhiteDot />
      <ProductBlackDot />
      <ProductBulbImage src={bulbImage} alt={''} title={'idea icon'} />
      <ProductMagnifierImage
        src={magnifierImage}
        alt={''}
        title={'magnifier icon'}
      />
    </ProductWrapper>
  </ProductSection>
)

export default OfferProductSection
